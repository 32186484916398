import React, { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { compose } from 'redux';
import { MyAppProps, MyPageContext } from 'kp';

import redirectRequest from '@lib/redirectRequest';

import { withAuthSyncComponent } from '@hocs/withAuthSync';
import { withPrefetchComponent } from '@hocs/withPrefetch';

import { HomeRoute } from 'server/routes';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectSuccessfulLogin } from '@store/auth/selector';
import { setToken } from '@store/auth/thunk';
import {
  fetchAccessControlInfo,
  fetchAdStats,
} from '@store/generalStats/thunk';
import { setPageType } from '@store/meta/slice';
import { wrapper } from 'src/store/configureStore';

import AuthHeaderButton from '@layouts/desktop/Header/AuthHeaderButton/AuthHeaderButton';
import { oneColSimpleLayout } from '@layouts/desktop/OneColumnSimpleLayout/layout';
import { mobileOneColSimpleLayout } from '@layouts/mobile/MobileOneColumnSimpleLayout/layout';
import AuthHeaderButtonMobile from '@layouts/mobile/MobileTopHeader/AuthHeaderButton/AuthHeaderButton';

import Seo from '@components/seo/responsive/Seo';

const SuccessLoginOverlay = dynamic(
  () =>
    import(
      '@components/oauth/responsive/SuccessLoginOverlay/SuccessLoginOverlay'
    )
);
const LoginPage = dynamic(
  () => import('@pages/responsive/LoginPage/LoginPage')
);

const LoginPageContainer = () => {
  const isSuccessfulLogin = useAppSelector(selectSuccessfulLogin);

  return (
    <>
      <Seo title="Ulogujte se" noindex nofollow />
      <LoginPage isSuccessfulLogin={isSuccessfulLogin} />
      {isSuccessfulLogin && <SuccessLoginOverlay />}
    </>
  );
};

LoginPageContainer.getLayout = (
  page: PropsWithChildren<{ props: MyAppProps }>
) =>
  oneColSimpleLayout({
    ...page,
    rightSide: (
      <AuthHeaderButton
        authButtonTitle="Registrujte se"
        title="Nemate nalog na kp?"
        authType="register"
      />
    ),
  });

LoginPageContainer.getLayoutMobile = (
  page: PropsWithChildren<{ props: MyAppProps }>
) =>
  mobileOneColSimpleLayout({
    ...page,
    rightSide: (
      <AuthHeaderButtonMobile
        authButtonTitle="Registrujte se"
        title="Nemate nalog?"
        authType="register"
      />
    ),
  });

LoginPageContainer.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (ctx: MyPageContext) => {
    const { query, res, req } = ctx;
    const { accessDenied = '' } = query;
    store.dispatch(setPageType('index'));
    if (req && accessDenied) {
      await store.dispatch(fetchAccessControlInfo());
      return { pageProps: {} };
    }
    if (accessDenied) {
      await store.dispatch(setToken(''));
      await store.dispatch(fetchAccessControlInfo());
    }
    if (store.getState().auth.token) {
      if (res) {
        redirectRequest({ ctx, url: '/', status: 302 });
      } else {
        Router.push(HomeRoute.generateUrl());
      }
    }
    await store.dispatch(fetchAdStats());

    return { pageProps: {} };
  }
);

export default compose(
  withAuthSyncComponent,
  withPrefetchComponent
)(LoginPageContainer);
